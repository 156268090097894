.container {
  height: 100vh;
  width: 100vw;
  background-color: rgb(0, 0, 0);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-image: url("../assets/klim-musalimov-1dC1c8V2w0s-unsplash.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  font-family: "Montserrat", sans-serif;
}

.filter {
  height: 100vh;
  width: 100vw;
  background-color: rgba(19, 14, 14, 0.956);
  opacity: 0.9;
  position: relative;
}

.mark {
  padding-right: 20px;
}

.text {
  color: rgb(210, 201, 201);
  text-align: center;
  width: fit-content;
  background-color: rgba(13, 11, 11, 0.742);
  padding: 40px;
  padding-top: 40px;
  padding-bottom: 30px;
  padding-right: 40px;
  box-sizing: border-box;
  box-shadow: 0px 0px 25px 10px #00000024;
}

.wrapper {
  width: 100vw;
  height: 100vh;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}
.left {
  text-align: left;
  margin-top: 0;
  font-weight: 400;
}

.middle {
  font-weight: 500;
  padding-left: 130px;
  padding-top: 20px;
  color: rgba(232, 220, 220, 0.669);
  font-size: 16px;
  margin-bottom: 5px;
}
.strong {
  color: rgba(237, 160, 160, 0.851);
  font-weight: 600;
  text-align: right;
  margin-bottom: 0;
  margin-top: 0;
}
* {
  box-sizing: border-box;
}
